import React from 'react';
import { X, Save, CheckSquare, Square, Printer } from 'lucide-react';
import { Transaction } from '../../types/transactions';
import { ChartOfAccount } from '../../types/chartOfAccounts';
import { useCompany } from '../../context/CompanyContext';
import { useReactToPrint } from 'react-to-print';
import { reportDates } from '../../utils/dates';

interface TransactionDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  transactions: Transaction[];
  accountName: string;
  accountNumber: string;
  isCurrentMonth: boolean;
  total: number;
}

export function TransactionDetailsModal({
  isOpen,
  onClose,
  transactions,
  accountName,
  accountNumber,
  isCurrentMonth,
  total
}: TransactionDetailsModalProps) {
  const { companyData, updateCompanyData, selectedCompany } = useCompany();
  const accounts = companyData?.accounts || [];
  const [selectedIds, setSelectedIds] = React.useState<Set<string>>(new Set());
  const [bulkCategory, setBulkCategory] = React.useState<string>('');
  const [showBulkEdit, setShowBulkEdit] = React.useState(false);
  const printRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Transactions_${accountName}_${isCurrentMonth ? 'CurrentMonth' : 'YTD'}`,
    pageStyle: `
      @media print {
        @page { size: portrait; margin: 20mm; }
        body { padding: 20px; }
        .no-print { display: none !important; }
      }
    `
  });

  const formatDate = (dateString: string): string => {
    try {
      return reportDates.formatForDisplay(dateString);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const handleToggleSelection = (id: string) => {
    const newSelection = new Set(selectedIds);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedIds(newSelection);
  };

  const handleSelectAll = () => {
    if (selectedIds.size === transactions.length) {
      setSelectedIds(new Set());
    } else {
      setSelectedIds(new Set(transactions.map(tx => tx.id)));
    }
  };

  const handleBulkUpdateCategory = () => {
    if (!bulkCategory || selectedIds.size === 0) return;

    const updatedTransactions = companyData?.transactions.map(tx => {
      if (selectedIds.has(tx.id)) {
        // Remove amount from old category's balance
        if (tx.category && tx.category !== 'Uncategorized') {
          const oldAccount = accounts.find(a => a.accountNumber === tx.category);
          if (oldAccount) {
            oldAccount.balance -= tx.amount;
          }
        }

        // Add amount to new category's balance
        if (bulkCategory !== 'Uncategorized') {
          const newAccount = accounts.find(a => a.accountNumber === bulkCategory);
          if (newAccount) {
            newAccount.balance += tx.amount;
          }
        }

        return { ...tx, category: bulkCategory };
      }
      return tx;
    }) || [];

    updateCompanyData({
      transactions: updatedTransactions,
      accounts
    });

    setSelectedIds(new Set());
    setBulkCategory('');
    setShowBulkEdit(false);
    
    setTimeout(() => onClose(), 500);
  };

  const PrintableContent = React.forwardRef<HTMLDivElement>((_, ref) => (
    <div ref={ref} className="p-8">
      <div className="text-center mb-8">
        <h1 className="text-2xl font-bold">{selectedCompany?.name}</h1>
        <h2 className="text-xl mt-2">{accountName} ({accountNumber})</h2>
        <p className="text-sm text-gray-600 mt-2">
          {isCurrentMonth ? 'Current Month' : 'Year to Date'} Transactions
        </p>
        <p className="text-sm text-gray-600 mt-1">
          Total: ${Math.abs(total).toFixed(2)}
        </p>
      </div>

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Amount
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Category
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {transactions.map((tx) => (
            <tr key={tx.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {formatDate(tx.date)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900">
                {tx.description}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                <span className={tx.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                  ${Math.abs(tx.amount).toFixed(2)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {accounts.find(a => a.accountNumber === tx.category)?.accountName || 'Uncategorized'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ));

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col">
        <div className="p-6 border-b border-gray-200 flex-shrink-0">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                {accountName} ({accountNumber})
              </h2>
              <p className="text-sm text-gray-500">
                {isCurrentMonth ? 'Current Month' : 'Year to Date'} Total: ${Math.abs(total).toFixed(2)}
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={handlePrint}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <Printer className="w-4 h-4 mr-2" />
                Print
              </button>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        {selectedIds.size > 0 && (
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200 flex-shrink-0">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-600">
                {selectedIds.size} transaction{selectedIds.size === 1 ? '' : 's'} selected
              </span>
              <div className="flex items-center space-x-4">
                <select
                  value={bulkCategory}
                  onChange={(e) => setBulkCategory(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select category...</option>
                  <option value="Uncategorized">Uncategorized</option>
                  {accounts.map(account => (
                    <option key={account.id} value={account.accountNumber}>
                      {account.accountNumber} - {account.accountName}
                    </option>
                  ))}
                </select>
                <button
                  onClick={handleBulkUpdateCategory}
                  disabled={!bulkCategory}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Update Category
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider no-print">
                  <button
                    onClick={handleSelectAll}
                    className="flex items-center text-gray-500 hover:text-gray-700"
                  >
                    {selectedIds.size === transactions.length ? (
                      <CheckSquare className="w-4 h-4" />
                    ) : (
                      <Square className="w-4 h-4" />
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transactions.map((tx) => (
                <tr 
                  key={tx.id}
                  className={selectedIds.has(tx.id) ? 'bg-indigo-50' : ''}
                >
                  <td className="px-6 py-4 whitespace-nowrap no-print">
                    <button
                      onClick={() => handleToggleSelection(tx.id)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      {selectedIds.has(tx.id) ? (
                        <CheckSquare className="w-4 h-4" />
                      ) : (
                        <Square className="w-4 h-4" />
                      )}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDate(tx.date)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {tx.description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                    <span className={tx.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                      ${Math.abs(tx.amount).toFixed(2)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {accounts.find(a => a.accountNumber === tx.category)?.accountName || 'Uncategorized'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Hidden printable content */}
        <div style={{ display: 'none' }}>
          <PrintableContent ref={printRef} />
        </div>
      </div>
    </div>
  );
}