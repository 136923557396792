import React from 'react';
import { X } from 'lucide-react';
import { useReactToPrint } from 'react-to-print';
import { StatementHeader } from './PrintableIncomeStatement/StatementHeader';
import { StatementTable } from './PrintableIncomeStatement/StatementTable';

interface PrintableIncomeStatementProps {
  companyName: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  categorizedTotals: Record<string, Record<string, { currentMonth: number; yearToDate: number }>>;
  totals: {
    revenue: { currentMonth: number; yearToDate: number };
    costOfSales: { currentMonth: number; yearToDate: number };
    expenses: { currentMonth: number; yearToDate: number };
    grossProfit: { currentMonth: number; yearToDate: number };
    netIncome: { currentMonth: number; yearToDate: number };
  };
  getAccountName: (accountNumber: string) => string;
  onClose: () => void;
}

export function PrintableIncomeStatement({
  companyName,
  dateRange,
  categorizedTotals,
  totals,
  getAccountName,
  onClose
}: PrintableIncomeStatementProps) {
  const printRef = React.useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: onClose,
    removeAfterPrint: true
  });

  React.useEffect(() => {
    handlePrint();
  }, [handlePrint]);

  const PrintContent = React.forwardRef<HTMLDivElement>((_, ref) => (
    <div ref={ref} className="bg-white p-8">
      <StatementHeader companyName={companyName} dateRange={dateRange} />
      <StatementTable 
        categorizedTotals={categorizedTotals} 
        totals={totals} 
        getAccountName={getAccountName} 
      />
    </div>
  ));

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-5xl w-full mx-4 p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Print Preview</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div style={{ display: 'none' }}>
          <PrintContent ref={printRef} />
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={handlePrint}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
}