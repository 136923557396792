import React from 'react';
import { Layout } from './components/Layout';
import { Dashboard } from './components/Dashboard';
import { TransactionManager } from './components/transactions/TransactionManager';
import { CategoryRulesPage } from './components/transactions/CategoryRulesPage';
import { ChartOfAccounts } from './components/transactions/ChartOfAccounts';
import { ReconcilePage } from './components/transactions/ReconcilePage';
import { Payroll } from './components/payroll/Payroll';
import { Reports } from './components/reports/Reports';
import { FileConverter } from './components/FileConverter';
import { FillForms } from './pages/FillForms';
import { InvoiceManager } from './components/invoices/InvoiceManager';
import { Home } from './pages/Home';
import { Auth } from './pages/Auth';
import { AdminAuth } from './pages/AdminAuth';
import { AdminDashboard } from './pages/admin/Dashboard';
import { Users } from './pages/admin/Users';
import { Practices } from './pages/admin/Practices';
import { CompanyProvider, useCompany } from './context/CompanyContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAdminAuth } from './hooks/useAdminAuth';

function AppContent() {
  const { selectedCompany } = useCompany();
  const { user, loading } = useAuth();
  const { adminUser, loading: adminLoading } = useAdminAuth();
  const [currentPage, setCurrentPage] = React.useState('dashboard');
  const [invoiceType, setInvoiceType] = React.useState<'in' | 'out'>('out');

  if (loading || adminLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  // Admin routes
  if (adminUser) {
    return (
      <Routes>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/practices" element={<Practices />} />
        <Route path="/admin/login" element={<Navigate to="/admin/dashboard" replace />} />
        <Route path="/admin/*" element={<Navigate to="/admin/dashboard" replace />} />
        <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      </Routes>
    );
  }

  // Regular user routes
  if (!user) {
    return (
      <Routes>
        <Route path="/admin/*" element={<AdminAuth />} />
        <Route path="*" element={<Auth />} />
      </Routes>
    );
  }

  // If no company selected for regular user, show home
  if (!selectedCompany) {
    return <Home />;
  }

  function renderPage() {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard />;
      case 'invoices-in':
        return <InvoiceManager type="in" />;
      case 'invoices-out':
        return <InvoiceManager type="out" />;
      case 'transactions':
        return <TransactionManager />;
      case 'reconcile':
        return <ReconcilePage />;
      case 'category-rules':
        return <CategoryRulesPage />;
      case 'chart-of-accounts':
        return <ChartOfAccounts standalone />;
      case 'payroll':
        return <Payroll />;
      case 'reports':
        return <Reports />;
      case 'file-converter':
        return <FileConverter />;
      case 'fill-forms':
        return <FillForms />;
      default:
        return <Dashboard />;
    }
  }

  return (
    <Layout 
      onNavigate={setCurrentPage} 
      currentPage={currentPage}
      onInvoiceTypeChange={setInvoiceType}
      invoiceType={invoiceType}
    >
      {renderPage()}
    </Layout>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CompanyProvider>
          <AppContent />
        </CompanyProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;